html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(7, 25, 38, 0.678),
    rgba(3, 7, 23, 0.863)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(7, 25, 38, 0.678),
    rgba(3, 7, 23, 0.863)
  );

  --imp-text-color: #83caf4;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c203f;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #17179a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(85, 158, 231, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #83caf4 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 2.5em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}
.active {
  font-size: 1.3rem;
  font-weight: bolder;
}
.active {
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #83caf4;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

.nav-resume {
  font-size: 1.1em !important;
  padding-top: 5px;
}

.nav-resume-inner {
  line-height: 1.4em !important;
  background-color: #83caf4 !important;
  padding: 0.7rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
  color: white !important;
}

.nav-resume-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #1b3ac4 !important;
  border-color: #0d4af286 !important;
  color: white;
}
.nav-resume-inner::after {
  display: none !important;
  color: white;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: -1;
  background-image: var(--image-gradient), url(./Assets/home-bg.jpg);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  height: 100vh !important;
  width: 100vw !important;
  object-fit: cover !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #83caf4;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #83caf4 !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #4c1761 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #57b3f5;
}

.home-social-icons:hover {
  color: #57b3f5;
  box-shadow: 0 0 5px #57b3f5;
  text-shadow: 0 0 2px #57b3f5;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #17179a !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(3, 20, 33);
  margin-top: auto;
  /* bottom: 0 !important; */
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer {
    display: none !important;
  }

  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */

/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(118, 190, 229, 0.459) !important;
  color: rgb(14, 32, 32) !important;
  background-color: #83caf4 !important;
  opacity: 1 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(118, 190, 229, 0.459) !important;
}

.card-img {
}

.card-img-top {
  padding: 20px !important;
  opacity: 1 !important;
  border-radius: 10px !important;
  height: 50%;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #19439c !important;
  border-color: #2047e4 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #67abebd7 !important;
  border-color: #19439c !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* Skills*/
/* --------- */
.skill-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.skill-button {
  width: 4em;
  height: 4em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 1em;
  border: transparent;
}

.skill-button-active {
  width: 4em;
  height: 4em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 1em;
  border: transparent;
  background-color: #e9b666;
}

.skill-button:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #57b3f5;
}

.skill-button:hover {
  color: #57b3f5;
  box-shadow: 0 0 10px #57b3f5;
  text-shadow: 0 0 2px #57b3f5;
}

.skill-title {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
  text-align: left;
  font-size: 2em;
  margin-top: 1.5rem;
}

.skill-image {
  height: 1em;
  width: 1em;
}

.skill-text {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
  text-align: justify;
  font-size: 1.1em;
}

/* --------- */
/* Certifications*/
/* --------- */
.certs-body-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cert-card {
  width: 30%;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
  flex: 0 0 30%;
  transition: all 0.2s ease-in-out;
}

.cert-header {
  margin: 0px;
  border-radius: 5px 5px 0px 0px;
}

.logo_img {
  margin: 3px;
  width: auto;
  height: 100px;
}

.cert-body {
  margin: 0px;
  border-radius: 10px;
}

.cert-body-title {
  /* font-family: "Google Sans Regular"; */
  font-size: 20px;
  margin: 10px;
}

.cert-body-subtitle {
  /* font-family: "Google Sans Regular"; */
  font-size: 18px;
  color: #808080;
  margin: 10px;
}

.content {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  font-family: "Google Sans Regular";
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .cert-card {
    width: 100%;
  }
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
  height: 100vh;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(126, 199, 242, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(66, 174, 227, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  color: white;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgb(126, 199, 242) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
  text-align: justify;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
